import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RouteComponent from "./routeComponent";
import i18n from "i18next";
import {
  AddCargoData,
  CompanyAddress,
  PickPartnerChangeRegistration,
  UserType,
  Document,
  Port,
  Incoterms,
  BookingRequest,
  RequestCollectionRegistration,
  SelectOption,
  ErrorTypes,
  DocumentError,
  HSCodes,
  BookingDetail,
  RouteBookingResponse,
  CargoBookingResponse,
  BookingDimension,
  UploadDocumentsResponse,
} from "@assets/types";
import AddDocumentsComponent from "./addDocumentsComponent";
import ReviewComponent from "./reviewComponent";
import Stepper from "@components/Stepper/stepper";
import Page from "@components/Page/page";
import CargoInformationComponent from "./cargoInformationComponent";
import {
  AcceptedFileType,
  AddressType,
  bills,
  DocumentReferenceType,
  fileMaxSize,
  fileNameSize,
  FreightMode,
  PageType,
  PortType,
  ShippingType,
  ShippingTypeValue,
} from "@assets/constants";
import { navigate } from "gatsby";
import { BillOfLading } from "@assets/types";
import CreateBookingService from "@services/CreateBookingService";
import HeaderPage from "@components/Page/headerPage";
import ArrayUtilities from "utilities/ArrayUtilities";
import SingleBookingService from "@services/SingleBookingService";
import { getPorts, portsState } from "@state/slices/portsSlice";
import { getUserProfileFromAPI, userState } from "@state/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@assets/hooks";
import {
  getCompanyAddresses,
  getCompanyCustomerAddresses,
  getPartnerAddress,
  partnerState,
} from "@state/slices/partnerSlice";
import { getIncoterms, incotermsState } from "@state/slices/incotermsSlice";
import {
  getShippingTypes,
  shippingTypesState,
} from "@state/slices/shippingTypesSlice";
import { getRouteBooking } from "@state/slices/routeBookingSlice";
import { getBookingCargo } from "@state/slices/cargoBookingSlice";

interface CreateTraceProps {
  path?: string;
  freightMode: FreightMode;
  pageType?: PageType;
  jobReference?: string;
  iframeUpdater: number;
  resetBookingState: Function;
}

const CreateTrace: React.FC<CreateTraceProps> = (props: CreateTraceProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const portsAll = useAppSelector(portsState);
  const userProfile = useAppSelector(userState).userProfile;
  const companyAddresses = useAppSelector(partnerState).companyAddresses;
  const loadingCompanyAddresses =
    useAppSelector(partnerState).loadingCompanyAddresses;
  const companyCustomerAddresses =
    useAppSelector(partnerState).companyCustomerAddresses;
  const loadingCompanyCustomerAddresses =
    useAppSelector(partnerState).loadingCompanyCustomerAddresses;
  const partnerAddresses = useAppSelector(partnerState).partnerAddress;
  const loadingPartnerAddresses =
    useAppSelector(partnerState).loadingPartnerAddresses;
  const incoterms = useAppSelector(incotermsState).incoterms;
  const loadingIncoterms = useAppSelector(incotermsState).loadingIncoterms;
  const shippingTypes = useAppSelector(shippingTypesState).shippingTypes;

  const [shipperData, setShipperData] =
    useState<PickPartnerChangeRegistration | null>(null);
  const [consigneeData, setConsigneeData] =
    useState<PickPartnerChangeRegistration | null>(null);
  const [notifierData, setNotifierData] =
    useState<PickPartnerChangeRegistration | null>(null);
  const [portsOfLoad, setPortsOfLoad] = useState<Port[] | null>(null);
  const [portsOfDischarge, setPortsOfDischarge] = useState<Port[] | null>(null);
  const [portOfLoad, setPortOfLoad] = useState<Port | null>(null);
  const [portOfDischarge, setPortOfDischarge] = useState<Port | null>(null);
  const [portFrom, setPortFrom] = useState<Port | null>(null);
  const [portTo, setPortTo] = useState<Port | null>(null);
  const [userType, setUserType] = useState<UserType>(UserType.Consignee);
  const [shippingType, setShippingType] = useState<string | null>(null);
  const [incoterm, setIncoterm] = useState<Incoterms | null>(null);
  const [incotermLocations, setIncotermLocations] = useState<Port[]>([]);
  const [selectedIncotermLocation, setSelectedIncotermLocation] =
    useState<Port | null>(null);
  const [selectedHSCodes, setSelectedHSCodes] = useState<HSCodes[]>([]);
  const [addCargoData, setAddCargoData] = useState<AddCargoData[][]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedStep, setSelectedStep] = useState<number>(0);
  const [fromData, setFromData] =
    useState<RequestCollectionRegistration | null>(null);
  const [toData, setToData] = useState<RequestCollectionRegistration | null>(
    null
  );
  const [quoteNumber, setQuoteNumber] = useState<string | null>(null);
  const [billOfLading, setBillOfLading] = useState<BillOfLading | null>(null);
  const [shipperError, setShipperError] = useState<string | null>(null);
  const [consigneeError, setConsigneeError] = useState<string | null>(null);
  const [podError, setPodError] = useState<string | null>(null);
  const [polError, setPolError] = useState<string | null>(null);
  const [collectionDetailsError, setCollectionDetailsError] = useState<
    string | null
  >(null);
  const [deliveryDetailsError, setDeliveryDetailsError] = useState<
    string | null
  >(null);
  const [incotermError, setIncotermError] = useState<string | null>(null);
  const [incotermLocationError, setIncotermLocationError] = useState<
    string | null
  >(null);
  const [billOfLadingError, setBillOfLadingError] = useState<string | null>(
    null
  );
  const [addCargoError, setAddCargoError] = useState<string | null>(null);
  const [invalidDocuments, setInvalidDocuments] = useState<boolean>(false);
  const [canCollectShipment, setCanCollectShipment] = useState<boolean>(true);
  const [canSetDeliverToPort, setCanSetDeliverToPort] =
    useState<boolean>(false);
  const [canDeliverShipment, setCanDeliverShipment] = useState<boolean>(true);
  const [canSetPickUpPort, setCanSetPickUpPort] = useState<boolean>(false);
  const [fallbackPort, setFallbackPort] = useState<Port | null>(null);
  const [loadingCreateBooking, setLoadingCreateBooking] =
    useState<boolean>(false);
  const [loadingCopyBooking, setLoadingCopyBooking] = useState<boolean>(true);
  const acceptedFileTypes: String[] = [
    AcceptedFileType.PDF,
    AcceptedFileType.JPEG,
    AcceptedFileType.XLSX,
    AcceptedFileType.DOC,
    AcceptedFileType.DOCX,
    AcceptedFileType.XLS,
  ];
  const [partnerAddress, setPartnerAddress] = useState<CompanyAddress | null>(
    null
  );

  useEffect(() => {
    if (props.pageType === PageType.CREATE) {
      resetState();
    }
    setUserType(UserType.Consignee);

    Promise.all([
      dispatch(getUserProfileFromAPI()).unwrap(),
      dispatch(
        getPorts(
          props.freightMode === FreightMode.AI
            ? PortType.POPULARAIR
            : PortType.POPULARSEA
        )
      ).unwrap(),
    ]).then(([user, ports]) => {
      dispatch(
        getCompanyAddresses({
          partnerCode: user?.SelectedCompany.PartnerCode,
          addressType:
            AddressType.DEFAULT + "," + AddressType.SHP + "," + AddressType.CEE,
        })
      );
      dispatch(
        getCompanyCustomerAddresses({
          partnerCode: user?.SelectedCompany.PartnerCode,
          addressType: AddressType.DEFAULT,
        })
      );

      dispatch(getIncoterms());
      dispatch(getShippingTypes());

      if (props.pageType === PageType.CREATE && userType === UserType.Shipper) {
        Promise.all([
          dispatch(
            getPartnerAddress({
              partnerCode: user?.SelectedCompany?.PartnerCode ?? "",
              addressType: AddressType.SHP,
            })
          ).unwrap(),
        ]).then(([pa]) => {
          setDataByUserType(pa);
          setPartnerAddress(pa);
        });
      }
      if (
        props.pageType === PageType.CREATE &&
        userType === UserType.Consignee
      ) {
        Promise.all([
          dispatch(
            getPartnerAddress({
              partnerCode: user?.SelectedCompany?.PartnerCode ?? "",
              addressType: AddressType.CEE,
            })
          ).unwrap(),
        ]).then(([pa]) => {
          setDataByUserType(pa);
          setPartnerAddress(pa);
        });
      }
    });

    if (props.pageType === PageType.COPY && props.jobReference) {
      Promise.all([
        dispatch(getRouteBooking(props.jobReference)).unwrap(),
        dispatch(getBookingCargo(props.jobReference)).unwrap(),
        dispatch(
          getPorts(
            props.freightMode === FreightMode.AI
              ? PortType.POPULARAIR
              : PortType.POPULARSEA
          )
        ).unwrap(),
      ]).then(([routeBooking, cargoBooking, ports]) => {
        initializeBooking(routeBooking, cargoBooking, ports).then(() => {
          setLoadingCopyBooking(false);
        });
      });
    }
  }, []);

  useEffect(() => {
    if (
      props.pageType === PageType.CREATE &&
      partnerAddress === null &&
      userProfile !== null
    )
      Promise.all([
        dispatch(
          getPartnerAddress({
            partnerCode: userProfile?.SelectedCompany?.PartnerCode ?? "",
            addressType: AddressType.DEFAULT,
          })
        ).unwrap(),
      ]).then(([pa]) => {
        setDataByUserType(pa);
        setPartnerAddress(pa);
      });
  }, [partnerAddress, userProfile]);

  useEffect(() => {
    setPortsOfLoad(portsAll);
    setPortsOfDischarge(portsAll);
  }, [portsAll]);

  useEffect(() => {
    let fallback_POD_POL_Port;
    if (props.freightMode === FreightMode.SH) {
      fallback_POD_POL_Port = ArrayUtilities.getObject<Port>(
        portsAll,
        "NLRTM",
        "PointCode"
      );
    } else {
      fallback_POD_POL_Port = ArrayUtilities.getObject<Port>(
        portsAll,
        "KEF",
        "PointCode"
      );
    }

    setFallbackPort(fallback_POD_POL_Port || null);
  }, [portsAll]);

  const handlePortOfLoadOnShipperDataChange = (
    shipper: PickPartnerChangeRegistration | null
  ) => {
    if (shipper?.placeCity?.CountryCode === "IS") {
      if (props.freightMode === FreightMode.SH) {
        const port = ArrayUtilities.getObject<Port>(
          portsAll,
          "ISREY",
          "PointCode"
        );
        setPortOfLoad(port || null);
      }
      if (props.freightMode === FreightMode.AI) {
        CreateBookingService.searchPorts({
          portType: PortType.AIR,
          country: "IS",
          searchString: "REK",
        }).then((p) => setPortOfLoad(p[0] || null));
      }
    } else {
      if (props.pageType === PageType.COPY) {
        setPortsOfLoad(portsAll);
        setPortOfLoad(portOfLoad);
      } else {
        setPortOfLoad(null);
      }
    }
  };

  const handlePortOfDischargeOnConsigneeDataChange = (
    consignee: PickPartnerChangeRegistration | null
  ) => {
    if (consignee?.placeCity?.CountryCode === "IS") {
      if (props.freightMode === FreightMode.SH) {
        const port = ArrayUtilities.getObject<Port>(
          portsAll,
          "ISREY",
          "PointCode"
        );
        setPortOfDischarge(port || null);
      }
      if (props.freightMode === FreightMode.AI) {
        CreateBookingService.searchPorts({
          portType: PortType.AIR,
          country: "IS",
          searchString: "REK",
        }).then((p) => {
          setPortOfDischarge(p[0] || null);
        });
      }
    } else {
      if (props.pageType === PageType.COPY) {
        setPortsOfDischarge(portsAll);
        setPortOfDischarge(portOfDischarge);
      } else {
        setPortOfDischarge(null);
      }
    }
  };

  const setDataByUserType = (partnerAddress: CompanyAddress | null) => {
    if (userType === UserType.Shipper) {
      const shipper = {
        companyName: userProfile?.SelectedCompany?.FullName ?? "",
        address: userProfile?.SelectedCompany?.Address2 ?? "",
        postalCode: userProfile?.SelectedCompany?.PostCode ?? "",
        placeCity: {
          PointCode: partnerAddress?.PointCode ?? "",
          FullName: partnerAddress?.City ?? "",
          Country: partnerAddress?.CountryName ?? "",
          CountryCode: partnerAddress?.CountryCode ?? "",
          PortCode: partnerAddress?.PostCode ?? "",
          LocalName: "",
          PortType:
            props.freightMode === FreightMode.AI ? PortType.AIR : PortType.SEA,
          TimeZone: "",
          TimeZoneDiff: 0,
        },
        contact: userProfile?.User?.Username ?? "",
        email: userProfile?.Access?.Email ?? "",
        phone: userProfile?.User?.Phone ?? "",
        partner: {
          PartnerCode: partnerAddress?.PartnerCode ?? "",
          FullName: partnerAddress?.FullName ?? "",
          PartnerSubCode: partnerAddress?.PartnerSubCode ?? "",
          CompanyCode: partnerAddress?.CompanyCode ?? "",
          AddressType: partnerAddress?.AddressType ?? "",
          AddressCode: partnerAddress?.AddressCode ?? "",
          PointCode: partnerAddress?.PointCode ?? "",
          PostCode: partnerAddress?.PostCode ?? "",
          Address1: partnerAddress?.Address1 ?? "",
          Address2: partnerAddress?.Address2 ?? "",
          Address3: partnerAddress?.Address3 ?? "",
          Address4: partnerAddress?.Address4 ?? "",
          CountryCode: partnerAddress?.CountryCode ?? "",
          StateCode: partnerAddress?.StateCode ?? "",
          CountryName: partnerAddress?.CountryName ?? "",
          AddressTypeDescription: partnerAddress?.AddressTypeDescription ?? "",
          PointName: partnerAddress?.PointName ?? "",
          ContactNumber: userProfile?.User?.Phone ?? "",
          ContactName: userProfile?.User?.Username ?? "",
          ContactEmail: userProfile?.User?.SendEmail ?? "",
          City: partnerAddress?.City ?? "",
          Currency: "",
        },
      };
      setShipperData(shipper);
      handlePortOfLoadOnShipperDataChange(shipper);
    }
    const consignee = {
      companyName: userProfile?.SelectedCompany?.FullName ?? "",
      address: userProfile?.SelectedCompany?.Address2 ?? "",
      postalCode: userProfile?.SelectedCompany?.PostCode ?? "",
      placeCity: {
        PointCode: partnerAddress?.PointCode ?? "",
        FullName: partnerAddress?.City ?? "",
        Country: partnerAddress?.CountryName ?? "",
        CountryCode: partnerAddress?.CountryCode ?? "",
        PortCode: partnerAddress?.PostCode ?? "",
        LocalName: "",
        PortType:
          props.freightMode === FreightMode.AI ? PortType.AIR : PortType.SEA,
        TimeZone: "",
        TimeZoneDiff: 0,
      },
      contact: userProfile?.User?.Username ?? "",
      email: userProfile?.Access?.Email ?? "",
      phone: userProfile?.User?.Phone ?? "",
      partner: {
        PartnerCode: partnerAddress?.PartnerCode ?? "",
        FullName: partnerAddress?.FullName ?? "",
        PartnerSubCode: partnerAddress?.PartnerSubCode ?? "",
        CompanyCode: partnerAddress?.CompanyCode ?? "",
        AddressType: partnerAddress?.AddressType ?? "",
        AddressCode: partnerAddress?.AddressCode ?? "",
        PointCode: partnerAddress?.PointCode ?? "",
        PostCode: partnerAddress?.PostCode ?? "",
        Address1: partnerAddress?.Address1 ?? "",
        Address2: partnerAddress?.Address2 ?? "",
        Address3: partnerAddress?.Address3 ?? "",
        Address4: partnerAddress?.Address4 ?? "",
        CountryCode: partnerAddress?.CountryCode ?? "",
        StateCode: partnerAddress?.StateCode ?? "",
        CountryName: partnerAddress?.CountryName ?? "",
        AddressTypeDescription: partnerAddress?.AddressTypeDescription ?? "",
        PointName: partnerAddress?.PointName ?? "",
        ContactNumber: userProfile?.User?.Phone ?? "",
        ContactName: userProfile?.User?.Username ?? "",
        ContactEmail: userProfile?.User?.SendEmail ?? "",
        City: partnerAddress?.City ?? "",
        Currency: "",
      },
    };
    setConsigneeData(consignee);
    handlePortOfDischargeOnConsigneeDataChange(consignee);
  };

  const resetState = () => {
    if (userType === UserType.Shipper) {
      setConsigneeData(null);
    } else setShipperData(null);
    setPortOfLoad(null);
    setPortOfDischarge(null);
    setPortFrom(null);
    setPortTo(null);
    setPortsOfLoad(null);
    setPortsOfDischarge(null);
    setUserType(UserType.Consignee);
    setShippingType(null);
    setIncoterm(null);
    setIncotermLocations([]);
    setSelectedHSCodes([]);
    setAddCargoData([]);
    setDocuments([]);
    setSelectedStep(0);
    setNotifierData(null);
    setFromData(null);
    setToData(null);
    setDataByUserType(null);
    setPodError(null);
    setPolError(null);
    setShipperError(null);
    setConsigneeError(null);
    setCollectionDetailsError(null);
    setDeliveryDetailsError(null);
    setIncotermError(null);
    setIncotermLocationError(null);
    setInvalidDocuments(false);
    setQuoteNumber(null);
    setBillOfLading(null);
    setBillOfLadingError(null);
    setLoadingCreateBooking(false);
  };

  const initializeBooking = async (
    routeBooking: RouteBookingResponse,
    cargoBooking: CargoBookingResponse,
    ports: Port[]
  ) => {
    let consigneePlace = routeBooking.Consignee?.PointCode
      ? await CreateBookingService.getPortByPointCode(
          routeBooking.Consignee?.PointCode,
          props.freightMode
        )
      : null;
    let shipperPlace = routeBooking.Shipper?.PointCode
      ? await CreateBookingService.getPortByPointCode(
          routeBooking.Shipper?.PointCode,
          props.freightMode
        )
      : null;
    let notifierPlace = routeBooking.Notifier?.PointCode
      ? await CreateBookingService.getPortByPointCode(
          routeBooking.Notifier?.PointCode,
          props.freightMode
        )
      : null;
    let colPlace = routeBooking.CollectionAddress?.PointCode
      ? await CreateBookingService.getPortByPointCode(
          routeBooking.CollectionAddress?.PointCode,
          props.freightMode
        )
      : null;
    let delPlace = routeBooking.DeliveryAddress?.PointCode
      ? await CreateBookingService.getPortByPointCode(
          routeBooking.DeliveryAddress?.PointCode,
          props.freightMode
        )
      : null;
    let consignee: PickPartnerChangeRegistration = {
      partner: {
        CompanyCode: routeBooking?.Consignee?.FullName ?? "",
        PartnerCode: routeBooking?.Consignee?.PartnerCode ?? "",
        PartnerSubCode: "",
        AddressType: routeBooking?.Consignee?.AddressType ?? "",
        AddressCode: "",
        FullName: routeBooking?.Consignee?.FullName ?? "",
        PointCode: routeBooking?.Consignee?.PointCode ?? "",
        PostCode: routeBooking?.Consignee?.PostCode ?? "",
        Address1: routeBooking?.Consignee?.Address1 ?? "",
        Address2: routeBooking?.Consignee?.Address2 ?? "",
        Address3: routeBooking?.Consignee?.Address3 ?? "",
        Address4: routeBooking?.Consignee?.Address4 ?? "",
        CountryCode: "",
        StateCode: "",
        CountryName: "",
        AddressTypeDescription: "",
        PointName: "",
        ContactNumber: routeBooking?.Consignee?.ContactNumber ?? "",
        ContactName: routeBooking?.Consignee?.ContactName ?? "",
        ContactEmail: routeBooking?.Consignee?.ContactEmail ?? "",
        City: "",
        Currency: routeBooking?.Consignee?.Currency ?? "",
      },
      placeCity: consigneePlace,
      companyName: routeBooking?.Consignee?.FullName ?? "",
      address: routeBooking?.Consignee?.Address2
        ? routeBooking?.Consignee?.Address2
        : routeBooking?.Consignee?.Address3 ?? "",
      postalCode: routeBooking?.Consignee?.PostCode ?? "",
      contact: routeBooking?.Consignee?.ContactName ?? "",
      email: routeBooking?.Consignee?.ContactEmail ?? "",
      phone: routeBooking?.Consignee?.ContactNumber ?? "",
    };
    let shipper: PickPartnerChangeRegistration = {
      partner: {
        CompanyCode: routeBooking?.Shipper?.FullName ?? "",
        PartnerCode: routeBooking?.Shipper?.PartnerCode ?? "",
        PartnerSubCode: "",
        AddressType: routeBooking?.Shipper?.AddressType ?? "",
        AddressCode: "",
        FullName: routeBooking?.Shipper?.FullName ?? "",
        PointCode: routeBooking?.Shipper?.PointCode ?? "",
        PostCode: routeBooking?.Shipper?.PostCode ?? "",
        Address1: routeBooking?.Shipper?.Address1 ?? "",
        Address2: routeBooking?.Shipper?.Address2 ?? "",
        Address3: routeBooking?.Shipper?.Address3 ?? "",
        Address4: routeBooking?.Shipper?.Address4 ?? "",
        CountryCode: "",
        StateCode: "",
        CountryName: "",
        AddressTypeDescription: "",
        PointName: "",
        ContactNumber: routeBooking?.Shipper?.ContactNumber ?? "",
        ContactName: routeBooking?.Shipper?.ContactName ?? "",
        ContactEmail: routeBooking?.Shipper?.ContactEmail ?? "",
        City: "",
        Currency: routeBooking?.Shipper?.Currency ?? "",
      },
      placeCity: shipperPlace,
      companyName: routeBooking?.Shipper?.FullName ?? "",
      address: routeBooking?.Shipper?.Address2
        ? routeBooking?.Shipper?.Address2
        : routeBooking?.Shipper?.Address3 ?? "",
      postalCode: routeBooking?.Shipper?.PostCode ?? "",
      contact: routeBooking?.Shipper?.ContactName ?? "",
      email: routeBooking?.Shipper?.ContactEmail ?? "",
      phone: routeBooking?.Shipper?.ContactNumber ?? "",
    };
    let notifier: PickPartnerChangeRegistration = {
      partner: {
        CompanyCode: routeBooking?.Notifier?.FullName ?? "",
        PartnerCode: routeBooking?.Notifier?.PartnerCode ?? "",
        PartnerSubCode: "",
        AddressType: routeBooking?.Notifier?.AddressType ?? "",
        AddressCode: "",
        FullName: routeBooking?.Notifier?.FullName ?? "",
        PointCode: routeBooking?.Notifier?.PointCode ?? "",
        PostCode: routeBooking?.Notifier?.PostCode ?? "",
        Address1: routeBooking?.Notifier?.Address1 ?? "",
        Address2: routeBooking?.Notifier?.Address2 ?? "",
        Address3: routeBooking?.Notifier?.Address3 ?? "",
        Address4: routeBooking?.Notifier?.Address4 ?? "",
        CountryCode: "",
        StateCode: "",
        CountryName: "",
        AddressTypeDescription: "",
        PointName: "",
        ContactNumber: routeBooking?.Notifier?.ContactNumber ?? "",
        ContactName: routeBooking?.Notifier?.ContactName ?? "",
        ContactEmail: routeBooking?.Notifier?.ContactEmail ?? "",
        City: "",
        Currency: routeBooking?.Notifier?.Currency ?? "",
      },
      placeCity: notifierPlace,
      companyName: routeBooking?.Notifier?.FullName ?? "",
      address: routeBooking?.Notifier?.Address2
        ? routeBooking?.Notifier?.Address2
        : routeBooking?.Notifier?.Address3 ?? "",
      postalCode: routeBooking?.Notifier?.PostCode ?? "",
      contact: routeBooking?.Notifier?.ContactName ?? "",
      email: routeBooking?.Notifier?.ContactEmail ?? "",
      phone: routeBooking?.Notifier?.ContactNumber ?? "",
    };
    let collectionAddress: RequestCollectionRegistration = {
      partner: {
        CompanyCode: routeBooking?.CollectionAddress?.FullName ?? "",
        PartnerCode: routeBooking?.CollectionAddress?.PartnerCode ?? "",
        PartnerSubCode: "",
        AddressType: routeBooking?.CollectionAddress?.AddressType ?? "",
        AddressCode: "",
        FullName: routeBooking?.CollectionAddress?.FullName ?? "",
        PointCode: routeBooking?.CollectionAddress?.PointCode ?? "",
        PostCode: routeBooking?.CollectionAddress?.PostCode ?? "",
        Address1: routeBooking?.CollectionAddress?.Address1 ?? "",
        Address2: routeBooking?.CollectionAddress?.Address2 ?? "",
        Address3: routeBooking?.CollectionAddress?.Address3 ?? "",
        Address4: routeBooking?.CollectionAddress?.Address4 ?? "",
        CountryCode: "",
        StateCode: "",
        CountryName: "",
        AddressTypeDescription: "",
        PointName: "",
        ContactNumber: routeBooking?.CollectionAddress?.ContactNumber ?? "",
        ContactName: routeBooking?.CollectionAddress?.ContactName ?? "",
        ContactEmail: routeBooking?.CollectionAddress?.ContactEmail ?? "",
        City: "",
        Currency: routeBooking?.CollectionAddress?.Currency ?? "",
      },
      placeCity: colPlace,
      companyName: routeBooking?.CollectionAddress?.FullName ?? "",
      address: routeBooking?.CollectionAddress?.Address2
        ? routeBooking?.CollectionAddress?.Address2
        : routeBooking?.CollectionAddress?.Address3 ?? "",
      postalCode: routeBooking?.CollectionAddress?.PostCode ?? "",
      contact: routeBooking?.CollectionAddress?.ContactName ?? "",
      email: routeBooking?.CollectionAddress?.ContactEmail ?? "",
      phone: routeBooking?.CollectionAddress?.ContactNumber ?? "",
      collectionDate: null,
      from: "",
      to: "",
      collectionReference: "",
      additionalInfo:
        routeBooking?.CollectionAddress?.SpecialInstructions ?? "",
    };
    let deliveryAddress: RequestCollectionRegistration = {
      partner: {
        CompanyCode: routeBooking?.DeliveryAddress?.FullName ?? "",
        PartnerCode: routeBooking?.DeliveryAddress?.PartnerCode ?? "",
        PartnerSubCode: "",
        AddressType: routeBooking?.DeliveryAddress?.AddressType ?? "",
        AddressCode: "",
        FullName: routeBooking?.DeliveryAddress?.FullName ?? "",
        PointCode: routeBooking?.DeliveryAddress?.PointCode ?? "",
        PostCode: routeBooking?.DeliveryAddress?.PostCode ?? "",
        Address1: routeBooking?.DeliveryAddress?.Address1 ?? "",
        Address2: routeBooking?.DeliveryAddress?.Address2 ?? "",
        Address3: routeBooking?.DeliveryAddress?.Address3 ?? "",
        Address4: routeBooking?.DeliveryAddress?.Address4 ?? "",
        CountryCode: "",
        StateCode: "",
        CountryName: "",
        AddressTypeDescription: "",
        PointName: "",
        ContactNumber: routeBooking?.DeliveryAddress?.ContactNumber ?? "",
        ContactName: routeBooking?.DeliveryAddress?.ContactName ?? "",
        ContactEmail: routeBooking?.DeliveryAddress?.ContactEmail ?? "",
        City: "",
        Currency: routeBooking?.DeliveryAddress?.Currency ?? "",
      },
      placeCity: delPlace,
      companyName: routeBooking?.DeliveryAddress?.FullName ?? "",
      address: routeBooking?.DeliveryAddress?.Address2
        ? routeBooking?.DeliveryAddress?.Address2
        : routeBooking?.DeliveryAddress?.Address3 ?? "",
      postalCode: routeBooking?.DeliveryAddress?.PostCode ?? "",
      contact: routeBooking?.DeliveryAddress?.ContactName ?? "",
      email: routeBooking?.DeliveryAddress?.ContactEmail ?? "",
      phone: routeBooking?.DeliveryAddress?.ContactNumber ?? "",
      collectionDate: null,
      from: "",
      to: "",
      collectionReference: "",
      additionalInfo: routeBooking?.DeliveryAddress?.SpecialInstructions ?? "",
    };
    let pol: Port = {
      PointCode: routeBooking?.POL?.PointCode ?? "",
      FullName: routeBooking?.POL?.FullName ?? "",
      Country: routeBooking?.POL?.Country ?? "",
      CountryCode: routeBooking?.POL?.CountryCode ?? "",
      PortCode: routeBooking?.POL?.PortCode ?? "",
      LocalName: routeBooking?.POL?.LocalName ?? "",
      PortType: routeBooking?.POL?.PortType ?? "",
      TimeZone: routeBooking?.POL?.TimeZone ?? "",
      TimeZoneDiff: routeBooking?.POL?.TimeZoneDiff ?? 0,
    };
    let pod: Port = {
      PointCode: routeBooking?.POD?.PointCode ?? "",
      FullName: routeBooking?.POD?.FullName ?? "",
      Country: routeBooking?.POD?.Country ?? "",
      CountryCode: routeBooking?.POD?.CountryCode ?? "",
      PortCode: routeBooking?.POD?.PortCode ?? "",
      LocalName: routeBooking?.POD?.LocalName ?? "",
      PortType: routeBooking?.POD?.PortType ?? "",
      TimeZone: routeBooking?.POD?.TimeZone ?? "",
      TimeZoneDiff: routeBooking?.POD?.TimeZoneDiff ?? 0,
    };
    let plr: Port = {
      PointCode: routeBooking?.PLR?.PointCode ?? "",
      FullName: routeBooking?.PLR?.FullName ?? "",
      Country: routeBooking?.PLR?.Country ?? "",
      CountryCode: routeBooking?.PLR?.CountryCode ?? "",
      PortCode: routeBooking?.PLR?.PortCode ?? "",
      LocalName: routeBooking?.PLR?.LocalName ?? "",
      PortType: routeBooking?.PLR?.PortType ?? "",
      TimeZone: routeBooking?.PLR?.TimeZone ?? "",
      TimeZoneDiff: routeBooking?.PLR?.TimeZoneDiff ?? 0,
    };
    let pfd: Port = {
      PointCode: routeBooking?.PFD?.PointCode ?? "",
      FullName: routeBooking?.PFD?.FullName ?? "",
      Country: routeBooking?.PFD?.Country ?? "",
      CountryCode: routeBooking?.PFD?.CountryCode ?? "",
      PortCode: routeBooking?.PFD?.PortCode ?? "",
      LocalName: routeBooking?.PFD?.LocalName ?? "",
      PortType: routeBooking?.PFD?.PortType ?? "",
      TimeZone: routeBooking?.PFD?.TimeZone ?? "",
      TimeZoneDiff: routeBooking?.PFD?.TimeZoneDiff ?? 0,
    };
    let incoterm = routeBooking?.IncoTerms
      ? await CreateBookingService.getIncotermByCode(
          routeBooking?.IncoTerms ?? ""
        )
      : null;

    let hsCodes: HSCodes[] = [];
    if (cargoBooking?.HsCodes !== undefined && cargoBooking?.HsCodes !== null) {
      cargoBooking?.HsCodes?.forEach((hs) => {
        hsCodes.push({
          HSCode: hs.HSCode ?? "",
          Description: hs.Description ?? "",
        });
      });
    }

    let cargoDataLCL: AddCargoData[] = [];
    let cargoDataFCL: AddCargoData[][] = [];

    cargoBooking?.Containers?.forEach((container) => {
      container?.ContainerData?.forEach((data) => {
        data?.Details?.forEach(async (detail, index) => {
          let commodity = detail.CommodityCode
            ? await CreateBookingService.getCommodityByCode(
                detail.CommodityCode
              )
            : null;
          let containerType = data?.ContainerType
            ? await CreateBookingService.getContainerTypeByCode(
                data?.ContainerType
              )
            : null;
          let unit = detail.PackageCode
            ? await CreateBookingService.getPackageTypeByCode(
                detail.PackageCode
              )
            : null;

          let marks: string[] = [];
          cargoBooking?.MarksAndNumbers?.forEach((mark) => {
            marks.push(mark.Description ?? "");
          });

          let hazards = props.jobReference
            ? await CreateBookingService.getHazardCodesByJobReference(
                props.jobReference,
                index + 1
              )
            : null;

          if (
            props.freightMode === FreightMode.SH &&
            routeBooking?.ShippingType === "F"
          ) {
            cargoDataFCL.push([
              {
                commodity: commodity,
                quantity: 0,
                containerType: containerType,
                containerShipperOwned: false, //
                containerNumber: "",
                containerSealNumber: "",
                unit: unit,
                description: detail?.Description ?? "",
                dimensions: [],
                length: 0,
                width: 0,
                height: 0,
                volume: 0,
                weight: 0,
                temperatureControl: detail?.IsRefrigerated ?? false,
                temperatureMin: detail?.MinTemperature?.toString() ?? "",
                temperatureMax: detail?.MaxTemperature?.toString() ?? "",
                stackable: detail?.IsStackable ?? false,
                hazardous: detail?.IsHazardous ?? false,
                unNumbers: hazards,
                outOfGauge: false,
                marksAndNumbers: cargoBooking?.MarksAndNumbers
                  ? marks?.join(";")
                  : "",
              },
            ]);
          } else {
            cargoDataLCL.push({
              commodity: commodity,
              quantity: 0,
              containerType: containerType,
              containerShipperOwned: false,
              containerNumber: container?.ContainerNumber ?? "",
              containerSealNumber: data?.SealNumber ?? "",
              unit: unit,
              description: detail?.Description ?? "",
              dimensions: [
                {
                  height: 0,
                  width: 0,
                  length: 0,
                  quantity: 0,
                },
              ],
              length: 0,
              width: 0,
              height: 0,
              volume: 0,
              weight: 0,
              temperatureControl: detail?.IsRefrigerated ?? false,
              temperatureMin: detail?.MinTemperature?.toString() ?? "",
              temperatureMax: detail?.MaxTemperature?.toString() ?? "",
              stackable: detail?.IsStackable ?? false,
              hazardous: detail?.IsHazardous ?? false,
              unNumbers: hazards,
              outOfGauge: false,
              marksAndNumbers: cargoBooking?.MarksAndNumbers
                ? marks?.join(";")
                : "",
            });
          }
        });
      });
    });

    let incotermLocation = ArrayUtilities.getObject<Port>(
      ports,
      routeBooking?.IncoTermsPoint ?? "",
      "FullName"
    );

    setShipperData(shipper);
    setConsigneeData(consignee);
    setNotifierData(routeBooking?.Notifier ? notifier : null);
    setPortOfLoad(pol);
    setPortOfDischarge(pod);
    setPortFrom(plr);
    setPortTo(pfd);

    if (
      userProfile?.SelectedCompany.PartnerCode === shipper.partner?.PartnerCode
    ) {
      setUserType(UserType.Shipper);
    } else if (
      userProfile?.SelectedCompany.PartnerCode ===
      consignee.partner?.PartnerCode
    ) {
      setUserType(UserType.Consignee);
    }

    setShippingType(
      routeBooking?.ShippingType === "L" ? ShippingType.LCL : ShippingType.FCL
    );
    setIncoterm(incoterm);
    setSelectedIncotermLocation(incotermLocation ? incotermLocation : null);
    setSelectedHSCodes(hsCodes);
    setAddCargoData(
      props.freightMode === FreightMode.SH && routeBooking?.ShippingType === "F"
        ? cargoDataFCL
        : [cargoDataLCL]
    );
    setSelectedStep(0);
    setFromData(collectionAddress);
    setToData(deliveryAddress);
    setQuoteNumber(routeBooking?.QuoteNumber ?? "");
    setBillOfLading(
      bills.find((x) => x.value === routeBooking?.BOLNumber) ?? null
    );
    if (routeBooking?.CollectionAddress != null) {
      setCanCollectShipment(true);
      setCanSetDeliverToPort(false);
    } else {
      setCanCollectShipment(false);
      setCanSetDeliverToPort(true);
    }
    if (routeBooking?.DeliveryAddress != null) {
      setCanDeliverShipment(true);
      setCanSetPickUpPort(false);
    } else {
      setCanDeliverShipment(false);
      setCanSetPickUpPort(true);
    }
  };

  const onChangePortOfLoad = async (option: Port) => {
    setPortOfLoad(option);

    let ports: Port[] = portsAll;

    if (props.freightMode === FreightMode.AI) {
      ports = await CreateBookingService.searchPorts({
        portType: PortType.POPULARAIR,
        searchString: "",
        country: "IS",
        size: undefined,
      });
      setPortsOfLoad(ports);
      return;
    }

    if (option != null) {
      CreateBookingService.getPortforPool(
        option?.PointCode,
        "POL",
        props.freightMode
      ).then((port: Port) => {
        if (port && props.freightMode === FreightMode.SH) setPortFrom(port);
        else setPortFrom(fallbackPort);
      });
      if (option?.CountryCode === "IS") {
        ports = portsAll.filter((y) => y.CountryCode != "IS");
      } else {
        ports = await CreateBookingService.searchPorts({
          portType: PortType.SEA,
          searchString: "",
          country: "IS",
          size: undefined,
        });
        if (!portOfDischarge) setPortOfDischarge(fallbackPort);
      }
    }

    setPortsOfDischarge(ports);
  };

  const onChangePortOfDischarge = async (option: Port) => {
    setPortOfDischarge(option);

    let ports: Port[] = portsAll;

    if (props.freightMode === FreightMode.AI) {
      ports = ports = portsAll.filter((y) => y.CountryCode != "IS");
      setPortsOfDischarge(ports);
      return;
    }

    if (option != null) {
      CreateBookingService.getPortforPool(
        option?.PointCode,
        "POL",
        props.freightMode
      ).then((port: Port) => {
        if (port && props.freightMode === FreightMode.SH) setPortTo(port);
        else setPortTo(fallbackPort);
      });

      if (option?.CountryCode === "IS") {
        ports = portsAll.filter((y) => y.CountryCode != "IS");
      } else {
        ports = await CreateBookingService.searchPorts({
          portType: PortType.SEA,
          searchString: "",
          country: "IS",
          size: undefined,
        });
      }
    }

    setPortsOfLoad(ports);
  };

  const onChangeShipperData = (shipperData: PickPartnerChangeRegistration) => {
    setShipperData(shipperData);
    handlePortOfLoadOnShipperDataChange(shipperData);
  };

  const onChangeConsigneeData = (
    consigneeData: PickPartnerChangeRegistration
  ) => {
    setConsigneeData(consigneeData);
    handlePortOfDischargeOnConsigneeDataChange(consigneeData);
  };

  const onChangeNotifierData = (
    notifierData: PickPartnerChangeRegistration
  ) => {
    setNotifierData(notifierData);
  };

  const onChangeShippingType = (shippingType: string) => {
    setShippingType(shippingType);
    setAddCargoData([]);
  };

  const onChangeIncoterm = (option: Incoterms) => {
    setIncoterm(option);
  };

  const onChangeIncotermLocation = (incotermLocations: Port[]) => {
    setIncotermLocations(incotermLocations);
  };

  const onChangeSelectedIncotermLocation = (option: Port) => {
    setSelectedIncotermLocation(option);
  };

  const onChangeQuoteNumber = (value: string) => {
    setQuoteNumber(value);
  };

  const onChangeBillOfLading = (value: BillOfLading) => {
    setBillOfLading(value);
  };

  const onChangeSelectedHSCodes = (hsCodes: HSCodes[]) => {
    setSelectedHSCodes([...hsCodes]);
  };

  const onChangeCargoData = (
    addCargo: AddCargoData,
    containerIndex: number | null
  ) => {
    let cargoData = structuredClone(addCargoData);
    if (containerIndex !== null && containerIndex !== undefined) {
      cargoData = [...cargoData, [addCargo]];
    } else {
      if (cargoData[0]) {
        cargoData[0] = [...cargoData[0], addCargo];
      } else {
        cargoData.push([addCargo]);
      }
    }
    setAddCargoData([...cargoData]);
  };

  const onDeleteCargoData = (
    cargoIndex: number,
    containerIndex: number | null
  ) => {
    if (containerIndex !== null && containerIndex !== undefined) {
      setAddCargoData([
        ...addCargoData.slice(0, containerIndex),
        ...addCargoData.slice(containerIndex + 1, addCargoData.length),
      ]);
    } else {
      let cargoData = structuredClone(addCargoData[0]);
      cargoData = [
        ...cargoData.slice(0, cargoIndex),
        ...cargoData.slice(cargoIndex + 1, cargoData.length),
      ];
      setAddCargoData([cargoData]);
    }
  };

  const onEditCargoData = (
    cargoIndex: number,
    containerIndex: number | null,
    addCargo: AddCargoData
  ) => {
    const cargoData = structuredClone(addCargoData);
    if (containerIndex !== null && containerIndex !== undefined) {
      cargoData[containerIndex][cargoIndex] = { ...addCargo };
    } else {
      cargoData[0][cargoIndex] = { ...addCargo };
    }
    setAddCargoData(cargoData);
  };

  const onDocumentAdded = (document: Document) => {
    setDocuments((oldDocs) => [...oldDocs, document]);
  };

  const onDocumentDeleted = (index: number) => {
    setDocuments([
      ...documents.slice(0, index),
      ...documents.slice(index + 1, documents.length),
    ]);
  };

  const onChangeSelectedDocType = (
    selectedDocType: SelectOption<string>,
    index: number
  ) => {
    const updatedList: Document[] = [...documents];
    const updatedDoc = {
      document: updatedList[index].document,
      doctype: selectedDocType.value,
    };
    updatedList[index] = updatedDoc;
    setDocuments(updatedList);
  };

  const createBookingRequest = (): BookingRequest => {
    let plr;
    if (fromData?.placeCity != null) {
      plr = fromData.placeCity;
    }
    let pfd;
    if (toData?.placeCity != null) {
      pfd = toData.placeCity;
    }

    let bl;
    if (userType === UserType.Shipper && props.freightMode === FreightMode.SH) {
      bl = billOfLading?.value ?? "";
    } else {
      bl = "G";
    }

    const details: BookingDetail[] = [];

    if (
      props.freightMode === FreightMode.SH &&
      shippingType?.charAt(0) === "F"
    ) {
      addCargoData.forEach((data) => {
        details.push({
          Dimensions: [
            {
              PackageCode: data[0]?.unit?.PackageCode,
              NumUnits: data[0]?.quantity,
              Weight: data[0]?.weight,
              Volume: data[0]?.volume,
              Length: data[0]?.length,
              Height: data[0]?.height,
              Width: data[0]?.width,
              Hazardous: data[0]?.unNumbers,
              Oversize: {
                OverHeight: data[0]?.height ?? null,
                OverLengthAfter: data[0]?.length ?? null,
                OverLengthFore: data[0]?.length ?? null,
                OverWidthLeft: data[0]?.width ?? null,
                OverWidthRight: data[0]?.width ?? null,
              },
            },
          ],
          MinTemperature: parseInt(data[0]?.temperatureMin),
          MaxTemperature: parseInt(data[0]?.temperatureMax),
          Temperature: null,
          ContainerNumber: data[0]?.containerNumber ?? null,
          ContainerSealNumber: data[0]?.containerSealNumber ?? null,
          Description: data[0]?.description,
          CommodityCode: data[0]?.commodity?.CommodityCode,
          Quantity: data[0]?.quantity,
          PackageCode:
            shippingType?.charAt(0) === "F"
              ? data[0]?.containerType?.PackageCode
              : undefined,
          Weight: data[0]?.weight,
          WeightUOM: "KGM",
          Volume: data[0]?.volume,
          VolumeUOM: "CBM",
          IsTemperatureControlled: data[0]?.temperatureControl,
          HazardFlag: data[0]?.hazardous,
          Marks: data[0]?.marksAndNumbers,
          MarksDescription: data[0]?.marksAndNumbers,
        } as BookingDetail);
      });
    } else {
      addCargoData.forEach((data) => {
        data.forEach((detail, cargoindex) => {
          details.push({
            Dimensions: data[cargoindex].dimensions.map((dimension) => {
              return {
                PackageCode: data[cargoindex]?.unit?.PackageCode ?? "",
                NumUnits: dimension?.quantity ?? 0,
                Weight: detail.weight ?? 0,
                Length: dimension?.length ?? 0,
                Height: dimension?.height ?? 0,
                Width: dimension?.width ?? 0,
                Hazardous: data[cargoindex]?.unNumbers ?? [],
                Oversize: null,
              } as BookingDimension;
            }),
            MinTemperature: parseInt(detail.temperatureMin),
            MaxTemperature: parseInt(detail.temperatureMax),
            Temperature: null,
            ContainerNumber: detail.containerNumber ?? null,
            ContainerSealNumber: detail.containerSealNumber ?? null,
            Description: detail.description,
            CommodityCode: detail.commodity?.CommodityCode,
            Quantity: detail.quantity,
            PackageCode:
              shippingType?.charAt(0) === "F"
                ? detail.containerType?.PackageCode
                : undefined,
            Weight: detail.weight,
            WeightUOM: "KGM",
            Volume: detail.volume,
            VolumeUOM: "CBM",
            IsTemperatureControlled: detail.temperatureControl,
            HazardFlag: detail.hazardous,
            Marks: detail.marksAndNumbers,
            MarksDescription: detail.marksAndNumbers,
          } as BookingDetail);
        });
      });
    }

    const bookingRequest: BookingRequest = {
      JobReference: null,
      ExternalJobReference: null,
      ControlNumber: null,
      DocumentReference: null,
      Shipper: {
        FullName: shipperData?.companyName,
        Address1: shipperData?.companyName,
        PointCode:
          shipperData?.partner?.PointCode != null
            ? shipperData?.partner?.PointCode
            : shipperData?.placeCity?.PointCode,
        PostCode: shipperData?.postalCode,
        ContactName: shipperData?.contact,
        ContactEmail: shipperData?.email,
        PartnerCode: shipperData?.partner?.PartnerCode,
        AddressType: AddressType.SHP,
        JobReference: "",
        Address2: shipperData?.address,
        Address3: shipperData?.district,
        Address4: shipperData?.placeCity?.FullName,
        Address5: shipperData?.placeCity?.CountryCode,
        ContactNumber: shipperData?.phone,
        RequestDate: undefined,
        RequestTime: undefined,
        RequestTolerance: undefined,
        SpecialInstructions: "",
        TheirRef: "",
        Currency: "",
        SSN: undefined,
        Town: "",
      },
      Consignee: {
        FullName: consigneeData?.companyName,
        Address1: consigneeData?.companyName,
        PointCode:
          consigneeData?.partner?.PointCode != null
            ? consigneeData?.partner?.PointCode
            : consigneeData?.placeCity?.PointCode,
        PostCode: consigneeData?.postalCode,
        ContactName: consigneeData?.contact,
        ContactEmail: consigneeData?.email,
        PartnerCode: consigneeData?.partner?.PartnerCode,
        AddressType: AddressType.CEE,
        JobReference: "",
        Address2: consigneeData?.address,
        Address3: consigneeData?.district,
        Address4: consigneeData?.placeCity?.FullName,
        Address5: consigneeData?.placeCity?.CountryCode,
        ContactNumber: consigneeData?.phone,
        RequestDate: undefined,
        RequestTime: undefined,
        RequestTolerance: undefined,
        SpecialInstructions: "",
        TheirRef: "",
        Currency: "",
        SSN: undefined,
        Town: "",
      },
      Notifier:
        notifierData != null
          ? {
              FullName: notifierData?.companyName,
              Address1: notifierData?.companyName,
              PointCode: notifierData?.partner?.PointCode,
              PostCode: notifierData?.postalCode,
              ContactName: notifierData?.contact,
              ContactEmail: notifierData?.email,
              PartnerCode: notifierData?.partner?.PartnerCode,
              AddressType: AddressType.NOT,
              JobReference: "",
              Address2: notifierData?.address,
              Address3: notifierData?.district,
              Address4: notifierData?.placeCity?.FullName,
              Address5: notifierData?.placeCity?.CountryCode,
              ContactNumber: notifierData?.phone,
              RequestDate: undefined,
              RequestTime: undefined,
              RequestTolerance: undefined,
              SpecialInstructions: "",
              TheirRef: "",
              Currency: "",
              SSN: undefined,
              Town: "",
            }
          : null,
      CollectionAddress: fromData?.companyName
        ? {
            FullName: fromData?.companyName,
            Address1: fromData?.companyName,
            PointCode: fromData?.placeCity?.PointCode,
            PostCode: fromData?.postalCode,
            ContactName: fromData?.contact,
            ContactEmail: fromData?.email,
            PartnerCode: fromData?.partner?.PartnerCode,
            AddressType: AddressType.COL,
            JobReference: "",
            Address2: fromData?.address,
            Address3: fromData?.district,
            Address4: fromData?.placeCity?.FullName,
            Address5: fromData?.placeCity?.CountryCode,
            ContactNumber: fromData?.phone,
            RequestDate: fromData?.collectionDate ?? undefined,
            RequestTime:
              parseInt(fromData?.from.replace(":", "") ?? "") ?? undefined,
            RequestTolerance:
              parseInt(fromData?.to.replace(":", "") ?? "") ?? undefined,
            SpecialInstructions: fromData?.additionalInfo,
            TheirRef: fromData?.collectionReference,
            Currency: "",
            SSN: undefined,
            Town: "",
          }
        : null,
      DeliveryAddress: toData?.companyName
        ? {
            FullName: toData?.companyName,
            Address1: toData?.companyName,
            PointCode: toData?.placeCity?.PointCode,
            PostCode: toData?.postalCode,
            ContactName: toData?.contact,
            ContactEmail: toData?.email,
            PartnerCode: toData?.partner?.PartnerCode,
            AddressType: AddressType.DEL,
            JobReference: "",
            Address2: toData?.address,
            Address3: toData?.district,
            Address4: toData?.placeCity?.FullName,
            Address5: toData?.placeCity?.CountryCode,
            ContactNumber: toData?.phone,
            RequestDate: undefined,
            RequestTime: undefined,
            RequestTolerance: undefined,
            SpecialInstructions: toData?.additionalInfo,
            TheirRef: toData?.collectionReference,
            Currency: "",
            SSN: undefined,
            Town: "",
          }
        : null,
      BookingPartyReference: null,
      BookingParty: userProfile?.SelectedCompany?.PartnerCode ?? null, //  PartnerCode of the current selected Company  => partnerCode of the one that is creating the booking (SHP or CEE)
      BookingPartyType: userType === UserType.Shipper ? "SHP" : "CEE", // CEE | SHP
      PLR: plr ?? null,
      PFD: pfd ?? null,
      POL: portOfLoad
        ? {
            PointCode: portOfLoad ? portOfLoad.PointCode : "",
            FullName: portOfLoad ? portOfLoad.FullName : "",
            Country: portOfLoad ? portOfLoad.Country : "",
            CountryCode: portOfLoad ? portOfLoad.CountryCode : "",
            PortCode: portOfLoad ? portOfLoad.PortCode : "",
            LocalName: portOfLoad ? portOfLoad.LocalName : "",
            PortType: portOfLoad ? portOfLoad.PortType : "",
            TimeZone: portOfLoad ? portOfLoad.TimeZone : "",
            TimeZoneDiff: portOfLoad ? portOfLoad.TimeZoneDiff : 0,
          }
        : null,
      POD: portOfDischarge
        ? {
            PointCode: portOfDischarge ? portOfDischarge.PointCode : "",
            FullName: portOfDischarge ? portOfDischarge.FullName : "",
            Country: portOfDischarge ? portOfDischarge.Country : "",
            CountryCode: portOfDischarge ? portOfDischarge.CountryCode : "",
            PortCode: portOfDischarge ? portOfDischarge.PortCode : "",
            LocalName: portOfDischarge ? portOfDischarge.LocalName : "",
            PortType: portOfDischarge ? portOfDischarge.PortType : "",
            TimeZone: portOfDischarge ? portOfDischarge.TimeZone : "",
            TimeZoneDiff: portOfDischarge ? portOfDischarge.TimeZoneDiff : 0,
          }
        : null,
      IncoTerms: incoterm ? incoterm?.TermsCode : "",
      IncoTermsPoint: selectedIncotermLocation
        ? selectedIncotermLocation?.PointCode
        : "",
      Status: 0,
      VoyageReference: null,
      ShippingType: shippingType?.includes("F")
        ? ShippingTypeValue.FF
        : ShippingTypeValue.LL,
      FreightMode: props.freightMode,
      Details: details,
      HsCodes: selectedHSCodes.length > 0 ? selectedHSCodes : null,
      TheirRef: fromData?.collectionReference ?? null,
      QuoteNumber: quoteNumber,
      BOLType: bl,
      Documents: [],
    };

    return bookingRequest;
  };

  const handleOnCreateBooking = async () => {
    setLoadingCreateBooking(true);
    const createBooking = createBookingRequest();

    try {
      const response = await CreateBookingService.createBooking(createBooking);

      createBooking.JobReference = response.JobReference;
      createBooking.ControlNumber = response.ControlNumber;
      createBooking.DocumentReference = response.DocumentReference;

      if (createBooking.JobReference != null) {
        navigate(
          `/createBooking/${props.freightMode}/${createBooking.JobReference}`
        );
        setLoadingCreateBooking(false);
      } else if (response.IsImportAirFreight) {
        setLoadingCreateBooking(false);
        navigate(`/createBooking/${props.freightMode}/${"importair"}`);
      } else if (response.ErrorMessage && !response.ControlNumber) {
        createBooking.JobReference = "Booking creation failed";
        setLoadingCreateBooking(false);
        navigate(`/createBooking/${props.freightMode}/${"failed"}`);
      } else {
        setLoadingCreateBooking(false);
        navigate(`/createBooking/${props.freightMode}/${"failed"}`);
      }

      if (userProfile?.Access.UserType == "C") {
        let termsNumber;
        if (response.JobReference != null) {
          termsNumber = response.JobReference;
        } else if (response.ControlNumber != null) {
          termsNumber = response.ControlNumber;
        } else {
          termsNumber = response.DocumentReference;
        }
        await CreateBookingService.acceptTerms("BOOKING_CREATE", termsNumber);
      }

      if (documents.length > 0) {
        createBooking.Documents = await handleUploadDocument(
          response.DocumentReference,
          response.JobReference,
          response.ControlNumber
        );
      }

      await CreateBookingService.sendBookingEmail(createBooking);
    } catch (error) {
      await CreateBookingService.sendBookingEmail(createBooking);
      setLoadingCreateBooking(false);
      navigate(`/createBooking/${props.freightMode}/${"failed"}`);
    }
  };

  const handleUploadDocument = async (
    documentReference: string,
    jobReference: string,
    controlNumber: string
  ): Promise<UploadDocumentsResponse[]> => {
    if (jobReference != null) {
      return await SingleBookingService.uploadDocuments(
        jobReference,
        documents.map((d) => d.doctype),
        DocumentReferenceType.JOBREFERENCE,
        documents.map((d) => d.document)
      );
    } else if (controlNumber != null) {
      return await SingleBookingService.uploadDocuments(
        controlNumber,
        documents.map((d) => d.doctype),
        DocumentReferenceType.CONTROLNUMBER,
        documents.map((d) => d.document)
      );
    } else {
      return await SingleBookingService.uploadDocuments(
        documentReference,
        documents.map((d) => d.doctype),
        DocumentReferenceType.OTHER,
        documents.map((d) => d.document)
      );
    }
  };

  let fromDataValidation: boolean = false;
  let toDataValidation: boolean = false;
  if (canCollectShipment && fromData != null) {
    fromDataValidation = true;
  }
  if (canDeliverShipment && toData != null) {
    toDataValidation = true;
  }
  if (canSetDeliverToPort && portOfLoad != null) {
    fromDataValidation = true;
  }
  if (canSetPickUpPort && portOfDischarge != null) {
    toDataValidation = true;
  }
  if (
    canSetDeliverToPort &&
    canSetPickUpPort &&
    portOfLoad != null &&
    portOfDischarge != null &&
    portOfLoad.PointCode == portOfDischarge.PointCode
  ) {
    toDataValidation = false;
    fromDataValidation = false;
  }

  const shipperValidation =
    shipperData != null &&
    shipperData.companyName != "" &&
    shipperData.address != "" &&
    shipperData.postalCode != "" &&
    shipperData.placeCity != null &&
    shipperData.contact != "" &&
    shipperData.email != "";
  const consigneeValidation =
    consigneeData != null &&
    consigneeData.companyName != "" &&
    consigneeData.address != "" &&
    consigneeData.postalCode != "" &&
    consigneeData.placeCity != null &&
    consigneeData.contact != "" &&
    consigneeData.email != "";

  const isValidRouteComponent = (): boolean => {
    let valid;
    userType === UserType.Shipper && props.freightMode === FreightMode.SH
      ? (valid =
          shipperValidation &&
          consigneeValidation &&
          fromDataValidation &&
          toDataValidation &&
          incoterm != null &&
          incoterm.TermsCode != "" &&
          selectedIncotermLocation != null &&
          billOfLading != null)
      : (valid =
          shipperValidation &&
          consigneeValidation &&
          fromDataValidation &&
          toDataValidation &&
          incoterm != null &&
          incoterm.TermsCode != "" &&
          selectedIncotermLocation != null);
    return valid;
  };

  const onHandleContinueRouteComponent = (): boolean => {
    if (isValidRouteComponent()) {
      setShipperError(null);
      setConsigneeError(null);
      setCollectionDetailsError(null);
      setPodError(null);
      setDeliveryDetailsError(null);
      setPolError(null);
      setIncotermError(null);
      setIncotermLocationError(null);
      setBillOfLadingError(null);
      setSelectedStep(1);
      return true;
    }

    let collectionError = null;
    let deliveryError = null;
    let portOfLoadError = null;
    let portOfDischargeError = null;

    if (!shipperValidation) {
      props.pageType === PageType.CREATE
        ? setShipperError(ErrorTypes.ShipperIsRequired)
        : setShipperError(ErrorTypes.ShipperDetailsRequireChange);
    } else setShipperError(null);
    if (!consigneeValidation) {
      props.pageType === PageType.CREATE
        ? setConsigneeError(ErrorTypes.ConsigneeIsRequired)
        : setConsigneeError(ErrorTypes.ConsigneeDetailsRequireChange);
    } else setConsigneeError(null);

    if (canCollectShipment && !fromData?.partner) {
      collectionError =
        collectionError ?? ErrorTypes.CollectionDetailsIsRequired;
    } else if (canCollectShipment && !fromData?.collectionReference) {
      collectionError = collectionError ?? ErrorTypes.EmptyCollectionDetails;
    } else {
      collectionError = collectionError ?? null;
      portOfDischargeError = portOfDischargeError ?? null;
    }

    if (canSetPickUpPort && !portOfDischarge) {
      portOfDischargeError = portOfDischargeError ?? ErrorTypes.RequiredField;
    } else {
      portOfDischargeError = portOfDischargeError ?? null;
      collectionError = collectionError ?? null;
    }

    if (canDeliverShipment && !toData?.partner) {
      deliveryError = deliveryError ?? ErrorTypes.DeliveryDetailsIsRequired;
    } else {
      deliveryError = deliveryError ?? null;
      portOfLoadError = portOfLoadError ?? null;
    }
    if (canSetDeliverToPort && !portOfLoad) {
      portOfLoadError = portOfLoadError ?? ErrorTypes.RequiredField;
    } else {
      deliveryError = deliveryError ?? null;
      portOfLoadError = portOfLoadError ?? null;
    }

    switch (portOfLoad?.PointCode) {
      case portOfDischarge?.PointCode: {
        portOfLoadError = portOfLoadError ?? ErrorTypes.SamePortNotPossible;
        portOfDischargeError =
          portOfDischargeError ?? ErrorTypes.SamePortNotPossible;
        break;
      }
      case toData?.placeCity?.PointCode: {
        portOfLoadError = portOfLoadError ?? ErrorTypes.SamePortNotPossible;
        deliveryError = deliveryError ?? ErrorTypes.SamePortNotPossible;
        break;
      }
    }
    switch (fromData?.placeCity?.PointCode) {
      case portOfDischarge?.PointCode: {
        collectionError = collectionError ?? ErrorTypes.SamePortNotPossible;
        portOfDischargeError =
          portOfDischargeError ?? ErrorTypes.SamePortNotPossible;
        break;
      }
      case toData?.placeCity?.PointCode: {
        collectionError = collectionError ?? ErrorTypes.SamePortNotPossible;
        deliveryError = deliveryError ?? ErrorTypes.SamePortNotPossible;
        break;
      }
    }

    setCollectionDetailsError(collectionError);
    setDeliveryDetailsError(deliveryError);
    setPodError(portOfDischargeError);
    setPolError(portOfLoadError);

    if (fromDataValidation && toDataValidation && incoterm == null) {
      setIncotermError(ErrorTypes.RequiredField);
    }
    if (
      fromDataValidation &&
      toDataValidation &&
      incoterm != null &&
      incoterm.TermsCode != "" &&
      selectedIncotermLocation == null
    ) {
      setIncotermLocationError(ErrorTypes.RequiredField);
    }

    if (
      billOfLading === null &&
      userType === UserType.Shipper &&
      props.freightMode === FreightMode.SH
    ) {
      setBillOfLadingError(ErrorTypes.RequiredField);
    }
    return false;
  };

  const onHandleContinueAddCargoComponent = (): boolean => {
    const invalidData = shippingType?.includes("F")
      ? addCargoData === null || addCargoData.length === 0
      : addCargoData[0] === null ||
        addCargoData[0] === undefined ||
        addCargoData[0]?.length === 0;
    if (
      props.pageType === PageType.CREATE &&
      invalidData &&
      onHandleContinueRouteComponent()
    ) {
      setAddCargoError(ErrorTypes.CargoDataIsRequired);
      return false;
    }

    if (props.pageType === PageType.COPY && onHandleContinueRouteComponent()) {
      let error = "";
      addCargoData.forEach((data) => {
        data.forEach((d) => {
          if (
            shippingType?.includes("F") &&
            !d.outOfGauge &&
            (!d.quantity || !d.volume || !d.weight)
          ) {
            error = ErrorTypes.EmptyCargoData;
          }
          if (
            !shippingType?.includes("F") &&
            (!d.quantity || !d.volume || !d.weight)
          ) {
            error = ErrorTypes.EmptyCargoData;
          }
        });
      });
      setAddCargoError(error);
      if (error) {
        return false;
      }
    }
    setAddCargoError(null);
    setSelectedStep(2);
    return true;
  };

  const getDocumentsErrors = (): DocumentError[] => {
    const errors: DocumentError[] = [];
    const doc = documents as Document[];
    for (let i = 0; i < documents.length; i++) {
      if (doc[i].document.name.length >= fileNameSize) {
        errors.push({
          errorType: ErrorTypes.FileNameSizeExceded,
          index: i,
        });
      }
      if (Math.round(doc[i].document.size / 1048576) >= fileMaxSize) {
        errors.push({
          errorType: ErrorTypes.FileSizeExceded,
          index: i,
        });
      }
      if (
        doc.filter((x) => x.document.name === doc[i].document.name).length >= 2
      ) {
        errors.push({ errorType: ErrorTypes.AlreadyExists, index: i });
      }
      if (!acceptedFileTypes.includes(doc[i].document.type)) {
        errors.push({ errorType: ErrorTypes.FileTypeNotSupported, index: i });
      }
      if (
        doc[i].doctype === undefined ||
        doc[i].doctype === null ||
        doc[i].doctype === ""
      ) {
        errors.push({
          errorType: ErrorTypes.MandatoryField,
          index: i,
        });
      }
    }
    return errors;
  };

  const onHandleContinueAddDocumentsComponent = (): boolean => {
    if (
      getDocumentsErrors().length <= 0 &&
      onHandleContinueRouteComponent() &&
      onHandleContinueAddCargoComponent()
    ) {
      setSelectedStep(3);
      return true;
    }
    setInvalidDocuments(true);
    return false;
  };

  const steps = [
    {
      title: i18n.t("LABEL_ROUTE"),
      onClick: () => {
        setSelectedStep(0);
      },
    },
    {
      title: i18n.t("LABEL_CARGO"),
      onClick: () => {
        onHandleContinueRouteComponent();
      },
    },
    {
      title: i18n.t("LABEL_DOCUMENTS"),
      onClick: () => {
        onHandleContinueAddCargoComponent();
      },
    },
    {
      title: i18n.t("LABEL_REVIEW"),
      onClick: () => {
        onHandleContinueAddDocumentsComponent();
      },
    },
  ];

  const onChangeUserType = (
    userType: UserType,
    partnerAddress: CompanyAddress | null
  ) => {
    setUserType(userType);
    let shipper: PickPartnerChangeRegistration | null = null;
    let consignee: PickPartnerChangeRegistration | null = null;
    if (userType === UserType.Shipper) {
      shipper = {
        companyName: userProfile?.SelectedCompany?.FullName ?? "",
        address:
          userProfile?.SelectedCompany?.Address2 !== null
            ? userProfile?.SelectedCompany?.Address2 ?? ""
            : userProfile?.SelectedCompany?.Address3 ?? "",
        postalCode: userProfile?.SelectedCompany?.PostCode ?? "",
        placeCity: {
          PointCode: partnerAddress?.PointCode ?? "",
          FullName: partnerAddress?.City ?? "",
          Country: partnerAddress?.CountryName ?? "",
          CountryCode: partnerAddress?.CountryCode ?? "",
          PortCode: partnerAddress?.PostCode ?? "",
          LocalName: "",
          PortType:
            props.freightMode === FreightMode.AI ? PortType.AIR : PortType.SEA,
          TimeZone: "",
          TimeZoneDiff: 0,
        },
        contact: userProfile?.User?.Username ?? "",
        email: userProfile?.Access?.Email ?? "",
        phone: userProfile?.User?.Phone ?? "",
        partner: {
          PartnerCode: partnerAddress?.PartnerCode ?? "",
          FullName: partnerAddress?.FullName ?? "",
          PartnerSubCode: partnerAddress?.PartnerSubCode ?? "",
          CompanyCode: partnerAddress?.CompanyCode ?? "",
          AddressType: partnerAddress?.AddressType ?? "",
          AddressCode: partnerAddress?.AddressCode ?? "",
          PointCode: partnerAddress?.PointCode ?? "",
          PostCode: partnerAddress?.PostCode ?? "",
          Address1: partnerAddress?.Address1 ?? "",
          Address2: partnerAddress?.Address2 ?? "",
          Address3: partnerAddress?.Address3 ?? "",
          Address4: partnerAddress?.Address4 ?? "",
          CountryCode: partnerAddress?.CountryCode ?? "",
          StateCode: partnerAddress?.StateCode ?? "",
          CountryName: partnerAddress?.CountryName ?? "",
          AddressTypeDescription: partnerAddress?.AddressTypeDescription ?? "",
          PointName: partnerAddress?.PointName ?? "",
          ContactNumber: userProfile?.User?.Phone ?? "",
          ContactName: userProfile?.User?.Username ?? "",
          ContactEmail: userProfile?.User?.SendEmail ?? "",
          City: partnerAddress?.City ?? "",
          Currency: "",
        },
      };
      consignee = {
        companyName: shipperData?.companyName ?? "",
        address: shipperData?.address ?? "",
        postalCode: shipperData?.postalCode ?? "",
        placeCity: shipperData?.placeCity ?? null,
        contact: shipperData?.contact ?? "",
        email: shipperData?.email ?? "",
        phone: shipperData?.phone ?? "",
        partner: shipperData?.partner ?? null,
      };

      setShipperData(shipper);
      setConsigneeData(consignee);
    } else {
      setUserType(userType);
      shipper = {
        companyName: consigneeData?.companyName ?? "",
        address: consigneeData?.address ?? "",
        postalCode: consigneeData?.postalCode ?? "",
        placeCity: consigneeData?.placeCity ?? null,
        contact: consigneeData?.contact ?? "",
        email: consigneeData?.email ?? "",
        phone: consigneeData?.phone ?? "",
        partner: consigneeData?.partner ?? null,
      };
      consignee = {
        companyName: userProfile?.SelectedCompany?.FullName ?? "",
        address:
          userProfile?.SelectedCompany?.Address2 !== null
            ? userProfile?.SelectedCompany?.Address2 ?? ""
            : userProfile?.SelectedCompany?.Address3 ?? "",
        postalCode: userProfile?.SelectedCompany?.PostCode ?? "",
        placeCity: {
          PointCode: partnerAddress?.PointCode ?? "",
          FullName: partnerAddress?.City ?? "",
          Country: partnerAddress?.CountryName ?? "",
          CountryCode: partnerAddress?.CountryCode ?? "",
          PortCode: partnerAddress?.PostCode ?? "",
          LocalName: "",
          PortType:
            props.freightMode === FreightMode.AI ? PortType.AIR : PortType.SEA,
          TimeZone: "",
          TimeZoneDiff: 0,
        },
        contact: userProfile?.User?.Username ?? "",
        email: userProfile?.Access?.Email ?? "",
        phone: userProfile?.User?.Phone ?? "",
        partner: {
          PartnerCode: partnerAddress?.PartnerCode ?? "",
          FullName: partnerAddress?.FullName ?? "",
          PartnerSubCode: partnerAddress?.PartnerSubCode ?? "",
          CompanyCode: partnerAddress?.CompanyCode ?? "",
          AddressType: partnerAddress?.AddressType ?? "",
          AddressCode: partnerAddress?.AddressCode ?? "",
          PointCode: partnerAddress?.PointCode ?? "",
          PostCode: partnerAddress?.PostCode ?? "",
          Address1: partnerAddress?.Address1 ?? "",
          Address2: partnerAddress?.Address2 ?? "",
          Address3: partnerAddress?.Address3 ?? "",
          Address4: partnerAddress?.Address4 ?? "",
          CountryCode: partnerAddress?.CountryCode ?? "",
          StateCode: partnerAddress?.StateCode ?? "",
          CountryName: partnerAddress?.CountryName ?? "",
          AddressTypeDescription: partnerAddress?.AddressTypeDescription ?? "",
          PointName: partnerAddress?.PointName ?? "",
          ContactNumber: userProfile?.User?.Phone ?? "",
          ContactName: userProfile?.User?.Username ?? "",
          ContactEmail: userProfile?.User?.SendEmail ?? "",
          City: partnerAddress?.City ?? "",
          Currency: "",
        },
      };

      setConsigneeData(consignee);
      setShipperData(shipper);
    }
    handlePortOfLoadOnShipperDataChange(shipper);
    handlePortOfDischargeOnConsigneeDataChange(consignee);
  };

  const onChangeFromData = (fromData: RequestCollectionRegistration) => {
    setFromData(fromData);
    setPortFrom(fromData.placeCity);
    if (fromData.placeCity != null) {
      CreateBookingService.getPortforPool(
        fromData.placeCity?.PointCode,
        "POL",
        props.freightMode
      ).then((port: Port) => {
        if (port && props.freightMode === FreightMode.SH) setPortOfLoad(port);
        else setPortOfLoad(fallbackPort);
      });
    }
  };

  const onChangeToData = (toData: RequestCollectionRegistration) => {
    setToData(toData);
    setPortTo(toData.placeCity);
    if (toData.placeCity != null) {
      CreateBookingService.getPortforPool(
        toData.placeCity?.PointCode,
        "POL",
        props.freightMode
      ).then((port: Port) => {
        if (port && props.freightMode === FreightMode.SH)
          setPortOfDischarge(port);
        else setPortOfDischarge(fallbackPort);
      });
    }
  };

  const onChangeCanCollectShipment = (canCollectShipment: boolean) => {
    setCanCollectShipment(canCollectShipment);
    setPolError(null);
  };

  const onChangeCanDeliverShipment = (canDeliverShipment: boolean) => {
    setCanDeliverShipment(canDeliverShipment);
    setPodError(null);
  };

  const onChangeCanSetDeliverToPort = (canSetDeliverToPort: boolean) => {
    setCanSetDeliverToPort(canSetDeliverToPort);
    setCollectionDetailsError(null);
  };

  const onChangeCanSetPickUpPort = (canSetPickUpPort: boolean) => {
    setCanSetPickUpPort(canSetPickUpPort);
    setDeliveryDetailsError(null);
  };

  return (
    <main className="flex flex-col items-center">
      <HeaderPage
        className="h-36 left-0 top-16 flex justify-center"
        backgroundColour="gray"
      >
        <div
          className={`flex-1 flex-col items-start overflow-hidden max-w-1024 top-24 bg-gray-100`}
        >
          <h1
            className={`static h-9 font-semibold text-2xl leading-9 px-6 flex items-center text-neutral-9 my-4 mt-6`}
          >
            {props.freightMode == FreightMode.SH
              ? t("CREATE_SEA_FREIGHT_BOOKING")
              : t("CREATE_AIR_FREIGHT_BOOKING")}
          </h1>
          <div className="pb-6 px-8 w-full h-full">
            <Stepper steps={steps} activeStep={selectedStep} />
          </div>
        </div>
      </HeaderPage>
      <Page className="md:m-6 justify-center w-375px md:w-1204px md:p-2">
        {selectedStep == 0 && (
          <RouteComponent
            portsOfLoad={portsOfLoad ?? []}
            portsOfDischarge={portsOfDischarge ?? []}
            onChangePortOfLoad={onChangePortOfLoad}
            onChangePortOfDischarge={onChangePortOfDischarge}
            onChangeShipperData={onChangeShipperData}
            shipperData={shipperData}
            onChangeConsigneeData={onChangeConsigneeData}
            consigneeData={consigneeData}
            onChangeNotifierData={onChangeNotifierData}
            notifierData={notifierData}
            companyAddresses={companyCustomerAddresses ?? []}
            portOfLoad={portOfLoad}
            portOfDischarge={portOfDischarge}
            userType={userType}
            partnerAddress={partnerAddress}
            onChangeUserType={onChangeUserType}
            incoterms={incoterms ?? []}
            incoterm={incoterm}
            incotermLocations={incotermLocations}
            selectedIncotermLocation={selectedIncotermLocation}
            onChangeIncoterm={onChangeIncoterm}
            onChangeIncotermLocation={onChangeIncotermLocation}
            onChangeSelectedIncotermLocation={onChangeSelectedIncotermLocation}
            fromData={fromData}
            toData={toData}
            onChangeFromData={onChangeFromData}
            onChangeToData={onChangeToData}
            shipperError={shipperError}
            consigneeError={consigneeError}
            podError={podError}
            polError={polError}
            collectionDetailsError={collectionDetailsError}
            deliveryDetailsError={deliveryDetailsError}
            incotermError={incotermError}
            incotermLocationError={incotermLocationError}
            onHandleContinue={() => onHandleContinueRouteComponent()}
            canCollectShipment={canCollectShipment}
            canDeliverShipment={canDeliverShipment}
            canSetDeliverToPort={canSetDeliverToPort}
            canSetPickUpPort={canSetPickUpPort}
            onChangeCanCollectShipment={onChangeCanCollectShipment}
            onChangeCanDeliverShipment={onChangeCanDeliverShipment}
            onChangeCanSetDeliverToPort={onChangeCanSetDeliverToPort}
            onChangeCanSetPickUpPort={onChangeCanSetPickUpPort}
            quoteNumber={quoteNumber}
            onChangeQuoteNumber={onChangeQuoteNumber}
            billOfLading={billOfLading}
            onChangeBillOfLading={onChangeBillOfLading}
            billOfLadingError={billOfLadingError}
            shipmentType={props.freightMode}
            loadingPartnerAddresses={loadingPartnerAddresses}
            loadingCompanyAddresses={loadingCompanyAddresses}
            loadingCompanyCustomerAddresses={loadingCompanyCustomerAddresses}
            loadingIncoterms={loadingIncoterms}
            loadingCopyBooking={loadingCopyBooking}
            pageType={props.pageType}
          />
        )}
        {selectedStep == 1 && (
          <CargoInformationComponent
            shippingTypes={shippingTypes ?? []}
            shippingType={shippingType}
            shipmentType={props.freightMode}
            onChangeShippingType={onChangeShippingType}
            addCargoData={addCargoData}
            selectedHSCodesList={selectedHSCodes}
            selectedHSCodes={onChangeSelectedHSCodes}
            onChangeCargoData={onChangeCargoData}
            onDeleteCargoData={onDeleteCargoData}
            setSelectedTab={setSelectedStep}
            onEditCargoData={onEditCargoData}
            cargoLinesError={addCargoError}
            onHandleContinue={onHandleContinueAddCargoComponent}
            loadingCopyBooking={loadingCopyBooking}
            pageType={props.pageType}
          />
        )}
        {selectedStep == 2 && (
          <AddDocumentsComponent
            freightMode={props.freightMode}
            documents={documents}
            onDocumentAdded={onDocumentAdded}
            onDocumentDeleted={onDocumentDeleted}
            onChangeSelectedDocType={onChangeSelectedDocType}
            setSelectedTab={setSelectedStep}
            onHandleContinue={onHandleContinueAddDocumentsComponent}
            getDocumentsErrors={getDocumentsErrors}
            invalidDocument={invalidDocuments}
          />
        )}
        {selectedStep == 3 && (
          <ReviewComponent
            userProfile={userProfile}
            shipperData={shipperData}
            consigneeData={consigneeData}
            notifierData={notifierData}
            portOfLoad={portOfLoad}
            portOfDischarge={portOfDischarge}
            shippingType={shippingType}
            incoterm={incoterm}
            incotermLocation={selectedIncotermLocation}
            addCargoData={addCargoData}
            documents={documents}
            hsCodes={selectedHSCodes}
            handleOnCreateBooking={handleOnCreateBooking}
            loadingCreateBooking={loadingCreateBooking}
            setSelectedTab={setSelectedStep}
            billOfLading={
              userType === UserType.Shipper &&
              props.freightMode === FreightMode.SH
                ? billOfLading?.label
                : bills.find((b) => b.value === "G")?.label
            }
            quoteNumber={quoteNumber}
            fromData={fromData}
            toData={toData}
            canCollectShipment={canCollectShipment}
            canDeliverShipment={canDeliverShipment}
            canSetDeliverToPort={canSetDeliverToPort}
            canSetPickUpPort={canSetPickUpPort}
            freightMode={props.freightMode}
          />
        )}
      </Page>
    </main>
  );
};

export default CreateTrace;
